import {Market} from '@hconnect/apiclient'

export const getMarketLabelForGermany = (market: Market): string => {
  if (!market.products || market.products.length === 0) {
    return market.marketName
  }
  const regex: RegExp = /\(([^)]+)\)/
  const businessLine = regex.exec(market.marketName)?.[0] || ''
  const region = market.marketName.split('(')[0] || ''
  return `${businessLine} ${region} (${market.products.join(', ')})`
}
