import type {SortDirection} from '@mui/material'
import i18next from 'i18next'

export const toggleSortDir = <K = string>(
  currentSortDir: SortDirection,
  columnKey: K,
  sortByKey: K
): SortDirection => (sortByKey === columnKey && currentSortDir === 'asc' ? 'desc' : 'asc')

export const sortByPriority = (
  priorityKeyA: string,
  priorityKeyB: string,
  valueA: string,
  valueB: string,
  priorities: Record<string, number>
) => {
  const priorityA = priorities[priorityKeyA] || Number.MAX_SAFE_INTEGER
  const priorityB = priorities[priorityKeyB] || Number.MAX_SAFE_INTEGER

  if (priorityA !== priorityB) {
    return priorityA - priorityB
  }

  return valueA.localeCompare(valueB, i18next.language, {sensitivity: 'base'})
}
